<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Чаты
        </li>
      </ol>
    </nav>
    <ChatList />
  </div>
</template>

<script>
import ChatList from '../components/ChatList'
export default {
  name: 'Chats',
  components: {
    ChatList,
  },
  mounted () {
    this.$socket.emit('get_data', ['chats', 'bots'])
  }
}
</script>

<style lang="scss" scoped>
</style>
