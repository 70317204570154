<template>
  <div class="">
    <div class="d-flex mb-3">
      <h3>Чаты</h3>
      <button
        class="btn btn-light ms-auto me-3"
        data-bs-toggle="modal"
        data-bs-target="#chatModal"
        @click="$store.commit('mergeStore', {chat: { tag_id, origin: 'tg_app' }})">
        Добавить чат в TG App
      </button>
      <button
        class="btn btn-light"
        @click="showDeleted = !showDeleted">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
      </button>

    </div>

    <div class="row">
      <div
        v-for="chat in chats"
        :key="chat.id"
        class="col-md-6 col-xl-4 mb-3">
        <div
          class="card card-body position-relative"
          :class="{
            'opacity-50': chat.deleted_at
          }">
          <h4 class="card-title">
            <router-link :to="{name: 'Chat', params: {id: chat.id}}">
              {{ chat.name || chat.origin_name || 'Чат' }}
            </router-link>
          </h4>
          <div class="text-black-50">
            <small
              v-if="editChatId != chat.id"
              @click="editChatId = chat.id"
              v-html="chat.desc ? chat.desc.replaceAll('\n', '<br>') : 'Описание'">
            </small>
            <div v-else class="">
              <textarea
                v-model="chat.desc"
                class="form-control mb-3"
                maxlength="100"
                rows="3"
                @focus="resize_textarea"
                @keyup="resize_textarea"
                placeholder="Описание">
              </textarea>
              <button
                @click="updateChat($event, chat)"
                class="btn btn-sm btn-secondary">
                Сохранить
              </button>
            </div>
          </div>
          <div class="d-flex mt-auto">
            <span class="btn btn-link ps-0 me-auto">
              <strong>{{ chat.account.first_name }} {{ chat.account.last_name }} {{ chat.account.username }}</strong>
            </span>
          </div>
          <p class="text-danger" v-if="chat.deleted_at">До окончательного удаления осталось дней: {{ getDaysForRemove(chat) }}</p>
          <div v-if="chat.deleted_at" class="">
            <button
              class="btn btn-success me-3"
              @click="restoreChat(chat)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
              </svg>
              Восстановить
            </button>
            <button
              class="btn btn-danger me-3"
              @click="deleteChat(chat)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg>
              Удалить
            </button>
          </div>
          <div v-else class="position-absolute bottom-0 end-0 btn-group">
            <!-- <button
              class="btn btn-link"
              @click="$store.commit('mergeStore', { chat })"
              data-bs-toggle="modal"
              data-bs-target="#chatModal">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </button> -->
            <div class="dropdown" v-if="chat.tags.length">
              <button class="btn btn-link" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-diagram-3-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"/>
                </svg>
              </button>
              <ul class="dropdown-menu">
                <li v-for="tag in chat.tags" :key="tag.id">
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'Tag', params: {id: tag.id} }">
                    {{ tag.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <button
              class="btn btn-link"
              @click="deleteChat(chat)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="chatModal"
      ref="chatModal"
      tabindex="-1"
      aria-labelledby="titleChatModal"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="titleChatModal">Чат в телеграме</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="$store.state.chat.id" class="">
              {{ $store.state.chat }}
            </div>
            <div v-else class="">
              <div  class="mb-3">
                <label class="form-label">Название</label>
                <input
                  type="text"
                  v-model="$store.state.chat.name"
                  class="form-control"
                  placeholder="Название">
              </div>
              <div  class="mb-3">
                <label class="form-label">Описание</label>
                <input
                  type="text"
                  v-model="$store.state.chat.desc"
                  class="form-control"
                  placeholder="Описание">
              </div>
              <div  class="mb-3">
                <label class="form-label">Начальная дата парсинга (дней назад)</label>
                <input
                  type="number"
                  v-model="$store.state.chat.timedelta"
                  class="form-control">
              </div>
              <div  class="mb-3">
                <label class="form-label">URL публичной группы / канала</label>
                <input
                  type="text"
                  v-model="$store.state.chat.url"
                  class="form-control"
                  placeholder="https://t.me/MID_Russia">
              </div>
              <!-- <div  class="mb-3">
                <label class="form-label">Имя в телеграме</label>
                <input
                  type="text"
                  v-model="$store.state.chat.telegram_name"
                  readonly
                  class="form-control"
                  placeholder="MID_Russia">
              </div> -->
              <div class="mb-2 text-secondary text-center">
                или
              </div>
              <div  class="mb-3">
                <label class="form-label">Ссылка на приватный чат</label>
                <input
                  type="text"
                  v-model="$store.state.chat.link"
                  class="form-control"
                  placeholder="https://t.me/+Np_vxJeGxVxmNWE6">
              </div>
              <div  class="mb-3">
                <label class="form-label">Аккаунт</label>
                <select class="form-select" v-model="$store.state.chat.account_id">
                  <option
                    v-for="account in $store.state.bots"
                    :key="account.id"
                    :value="account.id">
                    {{ account.first_name }} {{ account.last_name }} {{ account.username }} {{ account.phone }}
                  </option>
                </select>
                <p class="text-secondary">Данный акканут добавится в чат (если отсутствует в нем) и будет парсить сообщения в БД. При удалении чата из интерфейса аккаунт остается в чате</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!$store.state.chat.id" class="btn btn-primary me-3" @click="saveChat">Добавить</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
moment.locale('ru')
import { Modal } from 'bootstrap'
export default {
  created: function () {
    this.moment = moment
  },
  methods: {
    saveChat () {
      if (!this.$store.state.chat.id && !this.$store.state.chat.timedelta) {
        return this.$toast.error(`Введите промежуток парсинга перед добавлением чата`)
      }
      this.$socket.emit('chats', this.$store.state.chat)
    },
    getDaysForRemove (chat) {
      return Math.ceil(30 - (this.moment().unix() - chat.deleted_at) / 86400)
    },
    resize_textarea (event) {
      const textarea = event.target
      textarea.style.height = 0
      textarea.style.height = (textarea.scrollHeight) + 'px'
    },
    deleteChat (chat) {
      if (confirm('Удалить чат и все сообщения?')) {
        this.$socket.emit('delete_chat', chat)
      }
    },
    restoreChat (chat) {
      if (confirm('Восстановить чат?')) {
        this.$socket.emit('restore_chat', chat)
      }
    },

    updateChat (e, chat) {
      e.preventDefault()
      this.$socket.emit('chats', chat)
      this.editChatId = null
    },
  },

  computed: {
    chats () {
      if (this.showDeleted) {
        return this.$store.state.chats
      } else {
        return this.$store.state.chats.filter(c => !c.deleted_at)
      }
    }
  },

  data () {
    return {
      tag_id: null,
      editChatId: null,
      showDeleted: false
    }
  },

  async mounted () {
    try {
      const chatModal = new Modal(this.$refs.chatModal)
      this.$store.commit('mergeStore', { chatModal })
      if (this.$route.name === 'Tag') {
        this.tag_id = this.$route.params.id
      }
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  .btn-group{
    visibility: hidden;
  }
  &:hover{
    .btn-group{
      visibility: visible;
    }
  }
}
</style>
